import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const NewWindow = ({ setCount, count, projectId }) => {
  const [newWindowName, setNewWindowName] = useState()
  const [isNewWindowVisible, setNewWindowVisible] = useState(false)

  const addWindow = async () => {
    const data = {
      fields: {
        "Window name": newWindowName,
        Project: [projectId],
      },
    }

    const response = await fetch("/.netlify/functions/create-window", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    setCount(count => {
      return count + 1
    })

    setNewWindowVisible(false)

    toast.success("Window added", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
    })
  }

  return (
    <>
      <div
        style={{ marginBottom: 20 }}
        onClick={() => {
          setNewWindowVisible(!isNewWindowVisible)
        }}
      >
        + Indsæt vindue
      </div>
      {isNewWindowVisible && (
        <div style={{ marginBottom: 20 }}>
          <label className="size-input">
            <input
              type="text"
              className="fs--s project-input"
              onChange={e => {
                setNewWindowName(e.target.value)
              }}
              placeholder="Window name"
              required
            />
          </label>
          <input
            type="submit"
            className="button fs--s primary project-submit-button"
            onClick={addWindow}
          />
        </div>
      )}
    </>
  )
}

export default NewWindow
