import React, { useState, useEffect } from "react"

const InstagramImages = ({ windowInstagramImages }) => {
  const [instagramImages, setInstagramImages] = useState()

  useEffect(() => {
    const fetchInstagramImages = async imageIds => {
      const response = await fetch(
        "/.netlify/functions/fetch-instagram-images?ids=" + imageIds,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      let images = await response.json()
      if (images.length > 0) {
        setInstagramImages(images)
      }
    }

    if (!windowInstagramImages) {
      return
    }

    fetchInstagramImages(windowInstagramImages)
  }, [])

  return (
    <>
      {instagramImages && (
        <span style={{ fontWeight: "bold" }}>Inspirationsbilleder - </span>
      )}
      {instagramImages &&
        instagramImages.map((image, idx) => {
          return (
            <a
              href={
                image.fields && image.fields.image && image.fields.image[0].url
              }
              target="_blank"
              style={{ marginRight: 5 }}
            >
              Link
            </a>
          )
        })}
      {instagramImages && <br />}
    </>
  )
}

export default InstagramImages
