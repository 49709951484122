import React, { useState, useEffect, useRef } from "react"
import Spinner from "react-spinner-material"
import Details from "./Details"
import _ from "lodash"
import ImageUpload from "./ImageUpload"

const Windows = ({
  window,
  internal,
  status,
  projectId,
  excludeVAT,
  refetch,
  setRefetch,
}) => {
  const [count, setCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [isSaved, setSaved] = useState(false)
  const [isClaimNote, setClaimNote] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const formEl = useRef()
  const isCompleted = window.fields.Completed

  useEffect(() => {
    setShowDetails(!isCompleted)
  }, [isCompleted])

  const handleMeasureSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const windowId = event.target.window_id.value
    const height = event.target.height.value
    const width = event.target.width.value
    const noteFromCraftsMan = event.target["note-from-craftsman"]?.value
    const claimNoteFromCraftsMan =
      event.target["claim-note-from-craftsman"]?.value

    const internalFieldsToUpdate = internal
      ? {
          "Note from craftsman": noteFromCraftsMan,
          "Claim note from craftsman": claimNoteFromCraftsMan,
        }
      : {}

    const data = {
      fields: {
        "Measurement height": height,
        "Measurement width": width,
        ...internalFieldsToUpdate,
      },
    }

    const response = await fetch(
      `/.netlify/functions/update-window?id=${windowId}&projectId=${projectId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )

    setLoading(false)
    setSaved(true)

    setCount(count => {
      return count + 1
    })

    setTimeout(function() {
      setSaved(false)
    }, 3000)
  }

  const isDisabled =
    status === "Offer sent" ||
    status === "Unfulfilled" ||
    status === "Fulfilled"
      ? true
      : false

  const submitForm = _.debounce(() => {
    formEl.current.dispatchEvent(new Event("submit"))
  }, 300)

  const toggleClaimNote = () => {
    setClaimNote(isClaimNote => !isClaimNote)
  }

  return (
    <div className="window">
      <div
        className="card mb--m"
        style={{
          border: "1px solid #555",
        }}
      >
        <h2
          className="fs--m"
          style={{ fontSize: 25, fontWeight: "300", cursor: "pointer" }}
          onClick={() => {
            setShowDetails(showDetails => !showDetails)
          }}
        >
          {window.fields["Window name"]}
          {isCompleted && (
            <span style={{ fontSize: 14, marginLeft: 10, color: "green" }}>
              Færdig
            </span>
          )}
        </h2>
        <div style={{ display: showDetails ? "block" : "none" }}>
          <Details
            window={window}
            internal={internal}
            excludeVAT={excludeVAT}
          />

          {!internal && window.fields["Disclaimer"] && (
            <div style={{ marginTop: 10, fontSize: 12, fontWeight: "300" }}>
              <div style={{ fontWeight: 500 }}>Disclaimer</div>
              {window.fields["Disclaimer"]}
            </div>
          )}

          <div className="card mb--m" style={{ marginTop: 20 }}>
            <form onSubmit={handleMeasureSubmit} ref={formEl}>
              <input name="window_id" type="hidden" value={window.id} />

              <label className="size-input">
                <span className="label fs--s">
                  {isDisabled ? "Bredde" : "Indtast bredde"}
                </span>
                <div className="input-wrapper">
                  <input
                    name="width"
                    type="text"
                    placeholder="Bredde"
                    defaultValue={window.fields["Measurement width"]}
                    className="fs--s project-input"
                    required
                    disabled={isDisabled ? "disabled" : ""}
                    onChange={() => submitForm()}
                  />
                  <div className="unit-wrapper">
                    <span className="unit fs--xs co--light">cm</span>
                  </div>
                </div>
              </label>

              <label className="size-input" style={{ width: "30%" }}>
                <span className="label fs--s" style={{ marginTop: 20 }}>
                  {isDisabled ? "Højde" : "Indtast højde"}
                </span>
                <div className="input-wrapper">
                  <input
                    name="height"
                    type="text"
                    placeholder="Højde"
                    className="fs--s project-input"
                    defaultValue={window.fields["Measurement height"]}
                    required
                    disabled={isDisabled ? "disabled" : ""}
                    onChange={() => submitForm()}
                  />
                  <div className="unit-wrapper">
                    <span className="unit fs--xs co--light">cm</span>
                  </div>
                </div>
              </label>

              {internal && (
                <>
                  <label
                    className="size-input"
                    style={{ display: internal ? "block" : "none" }}
                  >
                    <span className="label fs--s" style={{ marginTop: 20 }}>
                      Note fra montør
                    </span>
                    <div className="input-wrapper">
                      <textarea
                        name="note-from-craftsman"
                        placeholder="Note"
                        className="fs--s project-input"
                        style={{ marginRight: 20 }}
                        defaultValue={window.fields["Note from craftsman"]}
                        disabled={isDisabled ? "disabled" : ""}
                        onChange={() => submitForm()}
                        style={{ height: "100px", marginBottom: 0 }}
                      ></textarea>
                    </div>
                  </label>
                  <div
                    onClick={toggleClaimNote}
                    style={{ fontSize: 12, cursor: "pointer" }}
                  >
                    Tilføj claim note
                  </div>
                </>
              )}

              {internal && (
                <label
                  className="size-input"
                  style={{ display: isClaimNote ? "block" : "none" }}
                >
                  <span className="label fs--s" style={{ marginTop: 20 }}>
                    Reklamations note fra montør
                  </span>
                  <div className="input-wrapper">
                    <textarea
                      name="claim-note-from-craftsman"
                      placeholder="Note"
                      className="fs--s project-input"
                      style={{ marginRight: 20 }}
                      defaultValue={window.fields["Claim note from craftsman"]}
                      disabled={isDisabled ? "disabled" : ""}
                      onChange={() => submitForm()}
                      style={{ height: "100px", marginBottom: 0 }}
                    ></textarea>
                  </div>
                </label>
              )}

              <br />
              {!isDisabled && (
                <button className="button fs--s primary project-submit-button">
                  <span style={{ color: "#fff" }}>Gem mål</span>
                  {"  "}
                  {isLoading && (
                    <Spinner
                      radius={15}
                      color={"#fff"}
                      stroke={1}
                      visible={true}
                    />
                  )}
                </button>
              )}
              {isSaved && (
                <div
                  style={{
                    background: "#519259",
                    textAlign: "center",
                    padding: 5,
                    color: "#fff",
                    marginTop: 10,
                  }}
                >
                  Oplysningerne er gemt
                </div>
              )}
            </form>
          </div>
          <div style={{ marginTop: 20, fontWeight: 500 }}>Billeder</div>
          <ImageUpload
            images={window.fields.Image}
            refetch={refetch}
            setRefetch={setRefetch}
            windowId={window.id}
          />
          {internal && (
            <>
              <div style={{ marginTop: 20, fontWeight: 500 }}>
                Billeder til internt brug
              </div>
              <ImageUpload
                images={window.fields["Internal images"]}
                refetch={refetch}
                setRefetch={setRefetch}
                windowId={window.id}
                internal
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Windows
