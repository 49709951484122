const formatPrice = price => {
  if (!price) {
    return
  }
  return price.toLocaleString("da-DK", {
    style: "currency",
    currency: "DKK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

export default formatPrice
