import React from "react"
import InstagramImages from "./InstagramImages"
import formatPrice from "../../utils/formatPrice"

const Details = ({ window, internal, excludeVAT }) => {
  let price = excludeVAT
    ? (parseInt(window.fields["Price"]) / 100) * 80
    : window.fields["Price"]

  return (
    <div>
      {window.fields["Custom window description"] && (
        <div>
          <span style={{ fontWeight: "bold" }}>Beskrivelse</span> -{" "}
          {window.fields["Custom window description"]}
        </div>
      )}

      {window.fields["Curtain fabric"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Gardin</span> -{" "}
          {window.fields["Curtain fabric"]} {window.fields["Color"]}
          <br />
        </>
      )}
      {window.fields["Inner curtain fabric"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Inder gardin</span> -{" "}
          {window.fields["Inner curtain fabric"]}{" "}
          {window.fields["Inner curtain color"]}
          <br />
        </>
      )}
      {window.fields["Pieces"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Antal gardinstykker</span> -{" "}
          {window.fields["Pieces"]}
          <br />
        </>
      )}

      {window.fields["Curtain details"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Gardin detaljer</span> -{" "}
          {window.fields["Curtain details"].map((detail, index) => {
            return (
              <span key={index}>
                {index > 0 ? " - " : ""}
                {detail}
              </span>
            )
          })}
          <br />
        </>
      )}

      {window.fields["Curtain look"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Gardin look</span> - Wave -{" "}
          {window.fields["Curtain look"] && (
            <>
              {window.fields["Curtain look"].map((detail, index) => {
                return (
                  <span key={index}>
                    {index > 0 ? " - " : ""}
                    {detail}
                  </span>
                )
              })}
            </>
          )}
          <br />
        </>
      )}

      {window.fields["Curtain pole"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Gardinskinne</span> -{" "}
          {window.fields["Curtain pole"]}
          <br />
        </>
      )}

      {window.fields["Track Details"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Gardinskinne detaljer</span> -{" "}
          {window.fields["Track Details"].map((detail, index) => {
            return (
              <span key={index}>
                {index > 0 ? " - " : ""}
                {detail}
              </span>
            )
          })}
          <br />
        </>
      )}

      {window.fields["Track detail"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Gardinskinne detaljer</span> -{" "}
          {window.fields["Track detail"].map((detail, index) => {
            return (
              <span key={index}>
                {index > 0 ? " - " : ""}
                {detail}
              </span>
            )
          })}
          <br />
        </>
      )}

      {window.fields["Note for customer"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Note</span> -{" "}
          {window.fields["Note for customer"]}
          <br />
        </>
      )}

      {internal && (
        <>
          <span style={{ fontWeight: "bold" }}>Final width</span> -{" "}
          {window.fields["Finished curtain width"]}
          <br />
          <span style={{ fontWeight: "bold" }}>Final height</span> -{" "}
          {window.fields["Finished curtain height"]}
          <br />
        </>
      )}

      <InstagramImages windowInstagramImages={window.fields["Instagram"]} />

      {!internal && (
        <>
          <span style={{ fontWeight: "bold" }}>Pris</span> -{" "}
          {formatPrice(price)} {excludeVAT ? "ex. moms" : "inkl. moms"}
          {window.fields["Inner curtain price"] && (
            <>
              {" "}
              (inkl. inder gardin:{" "}
              {formatPrice(window.fields["Inner curtain price"])})
            </>
          )}
          <br />
        </>
      )}

      {internal && window.fields["Note to craftsman"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Note til montør- </span>{" "}
          {window.fields["Note to craftsman"]}
          <br />
        </>
      )}

      {internal && window.fields["Measurement brief"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Measurement brief - </span>{" "}
          {window.fields["Measurement brief"]}
          <br />
        </>
      )}

      {internal && window.fields["Installation brief"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Installation brief - </span>{" "}
          {window.fields["Installation brief"]}
          <br />
        </>
      )}

      {internal && window.fields["Finished track details"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Finished track details - </span>{" "}
          {window.fields["Finished track details"]}
          <br />
        </>
      )}
    </div>
  )
}

export default Details
