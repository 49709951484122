import React, { useState, useRef } from "react"
import CheckSvg from "../../images/check.inline.svg"
import Spinner from "react-spinner-material"
import styles from "./styles.module.scss"

const ImageUpload = ({ images, refetch, setRefetch, windowId, internal }) => {
  const [isUploadingImage, setUploadingImage] = useState(false)
  const [isImageUploaded, setImageUploaded] = useState(false)
  const inputEl = useRef(null)

  const handleUpload = async windowId => {
    setUploadingImage(true)
    setImageUploaded(false)

    let file
    var reader = new FileReader()
    reader.readAsDataURL(inputEl.current.files[0])
    reader.onload = async function() {
      file = reader.result

      const imageData = {
        image: file,
      }

      const responseImage = await fetch(
        "/.netlify/functions/upload-image?id=" + windowId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(imageData),
        }
      )

      const image = await responseImage.json()

      let existingImages = images ? images : ""

      const imageField = internal ? "Internal images" : "Image"
      const windowData = {
        fields: {
          [imageField]: [
            ...existingImages,
            {
              url: image.imageUrl,
            },
          ],
        },
      }

      const response1 = await fetch(
        "/.netlify/functions/update-window?id=" + windowId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(windowData),
        }
      )

      setUploadingImage(false)
      setImageUploaded(true)
      inputEl.current.value = ""
      setRefetch(refetch => refetch + 1)
    }

    return
  }

  return (
    <div>
      {images &&
        images.map(image => {
          return (
            <div style={{ width: "20%", float: "left" }} key={image.url}>
              <a href={image.url} target="_blank">
                <img src={image.url} style={{ width: "100%" }} />
              </a>
            </div>
          )
        })}
      <div style={{ clear: "both" }}></div>
      <div className="file-upload ">
        <label className={`${styles.customFileUpload}`}>
          <input
            type="file"
            id="avatar"
            name="avatar"
            ref={inputEl}
            onChange={() => handleUpload(windowId)}
          />
          + Upload billede
        </label>
      </div>
      {isUploadingImage && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Spinner radius={15} color={"#000"} stroke={1} visible={true} />
          <span style={{ marginLeft: 5 }}>Uploader billede</span>
        </div>
      )}
      {isImageUploaded && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 5,
            color: "#007D58",
          }}
        >
          <CheckSvg />{" "}
          <span style={{ marginLeft: 5 }}>
            Billede er tilføjet. Du kan tilføje flere, hvis du ønsker
          </span>
        </div>
      )}
    </div>
  )
}

export default ImageUpload
